





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select-bordered.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import type { ICompSetItem } from '@/modules/cars/modules/settings/components/tabs/comp-set.tab.vue';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
        StyledSearchbar,
    },
})

export default class CompsetTableItem extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;

    @Prop({
        type: Object,
        required: true,
    })
    item!: ICompSetItem;

    @Prop({
        type: Array,
        required: true,
    })
    items!: ICompSetItem[];

    @Prop({
        type: Number,
        required: true,
    })
    index!: number;

    protected countryQuery: string = '';
    protected competitorQuery: string = '';
    protected isSearching: boolean = false;

    get dataSourceClass() {
        return this.item.dataSource ? '' : 'hasError';
    }

    get countryClass() {
        return this.item.country ? '' : 'hasError';
    }

    get competitorsClass() {
        return this.item.competitors.length ? '' : 'hasError';
    }

    get countryItems() {
        const { countryCodes } = this.carsFiltersService;

        if (!countryCodes) {
            return [];
        }

        let data = countryCodes.map(value => ({
            name: String(value.name),
            value: String(value.name),
        }));

        if (this.countryQuery) {
            const searchPattern = new RegExp(this.countryQuery.toLowerCase());
            data = data.filter(country => searchPattern.test(country.value.toLowerCase()));
        }

        return data;
    }

    handleCountryQueryChange(query: string) {
        this.countryQuery = query;
    }

    handleCountryChange() {
        this.$nextTick(() => {
            // Reset currently select data source if it's missed in available country data sources
            if (!this.dataSourceItems.some(item => item.value === this.item.dataSource)) {
                this.item.dataSource = '';
                this.handleDataSourceChange();
            }
        });
    }

    get dataSourceItems() {
        const availableDataSources = this.carsFiltersService.getAvailableDataSourceByCountry(this.item.country);

        return availableDataSources.map(value => ({
            name: value,
            value,
            disabled: this.items.some(item => item.key !== this.item.key && item.country === this.item.country && item.dataSource === value),
        }));
    }

    handleDataSourceChange() {
        this.$nextTick(() => {
            // Filter currently selected competitors from competitors available within selected country+dataSource pair
            Vue.set(this.item, 'competitors', this.item.competitors.filter(comp => this.competitorsItems.some(item => item.value === comp.value)));
        });
    }

    get competitorsItems() {
        const vendors: Record<string, string[]> = this.carsSharedService.vendorsList;
        const { competitorsFromCompsetSettings } = this.carsFiltersService;
        if (!this.item.dataSource || (!vendors[this.item.dataSource] && !competitorsFromCompsetSettings[this.item.dataSource]) && !this.isSearching) {
            return [];
        }
        let data = _.union(vendors[this.item.dataSource], competitorsFromCompsetSettings[this.item.dataSource]).map(value => ({
            name: String(value),
            value: String(value),
        }));

        if (this.competitorQuery) {
            const searchPattern = new RegExp(this.competitorQuery.toLowerCase());
            data = data.filter(competitor => searchPattern.test(competitor.value.toLowerCase()));
        }

        return data;
    }

    handleCompetitorsQueryChange(query: string) {
        this.competitorQuery = query;
        this.isSearching = true;
    }

    handleRemoveButton() {
        this.$emit('remove');
    }
}
