



























































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarNote from '@/modules/cars/components/car-note.vue';
import CompSetTabSettings from '../components/tabs/comp-set.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
        CarNote,
    },
})
export default class SettingsPage extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    tabs = [
        CompSetTabSettings,
    ];

    currentTab = +this.$route.query.tab || 0;

    setTab(index: number) {
        this.currentTab = index;
        this.updateQueryParameter();
    }

    updateQueryParameter() {
        if (this.currentTab === +this.$route.query.tab) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: { tab: String(this.currentTab) },
        });
    }

    get isReadonly() {
        return !this.carsSharedService.generalSettings;
    }
}
